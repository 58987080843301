import React from "react"
import { Link } from "gatsby"
import AboutContentHero from "./aboutHero"
import "./aboutContent.scss"
import AboutStories from "../about-stories/aboutStories"

const AboutContent = () => {
  return (
    <section>
      <div className="section-container about-content">
        <div className="about-content__hero">
          <AboutContentHero />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
              <div
                className="row"
                style={{ marginBottom: "1rem", padding: "0 1rem" }}
              >
                <h4 className="primary-text">Who We Are</h4>
                <p>
                  Codetrain was born out of the idea that when people fulfill
                  their dreams, they are able to positively impact their
                  communities.
                </p>
                <div className="col">
                  <h5>Our Vision</h5>
                  <p>
                    To be the leading software training brand worldwide,
                    creating significant impact in communities
                  </p>
                </div>
                <div className="col">
                  <h5>Our Mission</h5>
                  <p>
                    To empower people to rise digitally by training them to
                    become software developers and global leaders; setting them
                    up to make impact in their communities.
                  </p>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "1rem" }}>
                <div className="col-md-6">
                  <h4 className="primary-text">What is Codetrain</h4>
                  <p>
                    Our Training Program
                    <br /> <br />
                    <ul>
                      <li>
                        <h5>12 Months Practical Training</h5>
                        <p>
                          In-person training in full-stack web and mobile app
                          development.
                        </p>
                      </li>
                      <li>
                        <h5>Magic</h5>
                        <p>
                          Mentoring program during which our students get
                          internship opportunities, projects experience and
                          training to help them start their professional
                          careers.
                        </p>
                      </li>
                      <li>
                        <h5>Fellowship</h5>
                        <p>
                          Codetrain Alumni become part of a 5 year Fellowship
                          where they get continuous professional development and
                          also get to be a part of Codetrain's extensive
                          network.
                        </p>
                      </li>
                    </ul>
                  </p>
                </div>
                <div className="col-md-6">
                  <h4 className="primary-text">Where We Are</h4>
                  <p>
                    We are based in Ghana with an office in Accra.
                    <br /> <br />
                    <ul>
                      <li>
                        <h5>Accra</h5>
                        <p>East Legon 16a Parsnip street, near the A&C Mall</p>
                      </li>
                      {/* <li>
                        <h5>Kumasi</h5>
                        <p>
                          Codetrain hub - Kotei Gyinyase Road (Near KNUST),
                          Covet Junction.
                        </p>
                      </li> */}
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "4rem" }}>
        <div className="row">
          <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
            <div className="row" style={{ padding: "0 1rem" }}>
              <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                <h3 className="primary-text text-center">
                  Empowering African Youth To Make Dreams Come True
                </h3>
                <p className="text-center">
                  We pride ourselves in supporting talent in our communities.
                  Here are a few projects we have embarked on so far.
                </p>
                <br />
              </div>
              <AboutStories />
            </div>
          </div>
        </div>
      </div>
      <div className="section-container partner-content">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-md-6">
                <h3 className="primary-text">Partner with us</h3>
                <p>
                  Interested in partnering with Codetrain?
                  <br />
                  <Link to="/#contact">Contact us</Link>
                </p>
                {/* <p>
                  <a
                    href="#"
                    className="btn btn-light disabled"
                    tabindex="-1"
                    role="button"
                    aria-disabled="true"
                  >
                    rachel@codetraingh.com
                  </a>
                </p> */}
              </div>
              <div className="col-md-6">
                <h3 className="primary-text">Join our program</h3>
                <p>
                  Apply to Codetrain to become a professional web and mobile app
                  developer.
                </p>
                <p>
                  <Link to="/courses" className="btn-custom-primary-normal">
                    Enroll now
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutContent
