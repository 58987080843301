import React from "react"
import { Link } from "gatsby"
import "./mainNavbar.scss"
import logo from "../../assets/img/logos/logo.png"

const MainNavbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light sticky-top main-navbar">
      <Link className="navbar-brand" to="/">
        <img src={logo} alt="Codetrain" className="logo-img" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link
              className="nav-link"
              to="/success_stories/"
              tabIndex="-1"
              aria-disabled="true"
            >
              Success stories
            </Link>
          </li>
          <li className="nav-item dropdown">
            <a
              href="#"
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Our Courses
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/courses/">
                Software Engineering
              </Link>

              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/ux-ui-design/">
                UX/UI design
              </Link>
            </div>
          </li>
          {/* <li className="nav-item">
            <Link
              className="nav-link"
              to="/courses/"
              tabIndex="-1"
              aria-disabled="true"
            >
              Our course
            </Link>
          </li> */}
        </ul>
        <ul className="navbar-nav ml-auto">
          {/* <li className="nav-item dropdown">
            <a
              href="#"
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              About
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/about/">
                About Us
              </Link>
              <a className="dropdown-item" href="https://medium.com/@codetrain">
                Blog
              </a>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/careers/">
                Careers
              </Link>
            </div>
          </li> */}
          {/* <li className="nav-item">
            <Link
              className="nav-link"
              to="/program"
              tabIndex="-1"
              aria-disabled="true"
            >
              Program structure
            </Link>
          </li> */}
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://drive.google.com/open?id=1AStX5ZLx1A6OYXrYe2sAhDjhUBm8afbTeeZYAc3jNrg"
              tabIndex="-1"
              aria-disabled="true"
            >
              Hire our graduates
            </a>
          </li>
          {/* <li className="nav-item">
            <a
              className="nav-link test"
              href="https://codetrain.africa/student/login"
              tabIndex="-1"
              aria-disabled="true"
              target="blank"
            >
              Login
            </a>
          </li> */}
          {/* <li className="nav-item">
            <Link className="nav-link apply-btn" to="/program">
              Enroll Now <span className="sr-only">(current)</span>
            </Link>
          </li> */}
        </ul>
      </div>
    </nav>
  )
}

export default MainNavbar
