import React from "react";
import MainNavbar from "../components/main_navbar/MainNavbar";
import Footer from "./footer/Footer";

export default ({ children }) => (
  <section>
    <MainNavbar />
        {children}
    <Footer />
  </section>
)