import React from "react"
import "./aboutStories.scss"

import jbc from "../../assets/img/full-photos/jbc-collage.jpg"
import brianadet from "../../assets/img/full-photos/brianadetcollage.jpeg"
import quiz from "../../assets/img/full-photos/quiz-collage.jpg"
import talentedkids from "../../assets/img/full-photos/talentedkids-collage.jpeg"

const AboutStories = () => {
  return (
    <div className="about-stories-section">
      <div className="container about-stories-section__content">
        <div className="row">
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-ride="carousel"
            data-interval="10000"
          >
            <div className="carousel-inner">
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-xs-12 col-md-5 carousel-item-caption">
                    <div className="carousel-description">
                      <h3 className="carousel-title">
                        Coding Bootcamps for Kids
                      </h3>
                      <p className="text-muted">
                        Codetrain organizes several coding bootcamps within the
                        year in schools across Ghana to introduce kids to
                        coding . We believe that these young brilliant minds are
                        the future of tech talent in Africa .
                      </p>
                      <p className="text-muted">
                        Schools we have visited include SOS Herman Gmeiner
                        School, PRESEC Legon, Oasis Christian Academy, Ghana
                        Lebanon Islamic School (GLISS), Achimota School, etc.
                      </p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-5 carousel-item-image">
                    <img
                      src={jbc}
                      className="img-fluid mx-auto d-block"
                      alt="Kids Coding Bootcamps"
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-xs-12 col-md-5 carousel-item-caption">
                    <div className="carousel-description">
                      <h3 className="carousel-title">
                        Codetrain Sponsors High School Competition
                      </h3>
                      <p className="text-muted">
                        We sponsored The Ultimate High School Quiz 2019. With
                        close to 120 schools competing, Kumasi and its environs
                        saw smart scholars demonstrate in-depth knowledge in
                        various subjects.
                      </p>
                      <p className="text-muted">
                        Codetrain offered a scholarship of 6 months training to
                        the winning school and mentorship to all participants of
                        the the competition.
                      </p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-5 carousel-item-image">
                    <img
                      src={quiz}
                      className="img-fluid mx-auto d-block"
                      alt="Codetrain Sponsors Quiz"
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-xs-12 col-md-5 carousel-item-caption">
                    <div className="carousel-description">
                      <h3 className="carousel-title">
                        Ghana's TV3 Talented Kidz
                      </h3>
                      <p className="text-muted">
                        Codetrain is proud to sponsor the next generation of the
                        brightest minds through the TV3 Talented Kidz, the
                        biggest kids reality show in Ghana.
                      </p>
                      <p className="text-muted">
                        We introduced all the Talented Kidz contestants to
                        designing and building their own mobile apps and games.
                        Codetrain is also providing mentorship to the
                        contestants to help them achieve their dreams.
                      </p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-5 carousel-item-image">
                    <img
                      src={talentedkids}
                      className="img-fluid mx-auto d-block"
                      alt="Codetrain sponsors Ghana's TV3 Talented Kidz"
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-xs-12 col-md-5 carousel-item-caption">
                    <div className="carousel-description">
                      <h3 className="carousel-title">
                        Codetrain Supports Kids Soccer Foundation
                      </h3>
                      <p className="text-muted">
                        Shaping the early development of a child is essential to
                        inspiring the next generation dreamers. That’s why we
                        are supporting the BrianAdet Kids Soccer Foundation to
                        help transform the future of African football.
                      </p>
                      <p className="text-muted"></p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-5 carousel-item-image">
                    <img
                      src={brianadet}
                      className="img-fluid mx-auto d-block"
                      alt="Codetrain sponsors BrianAdet Kids Soccer Foundation"
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutStories
