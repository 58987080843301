import React from "react"
import { Link } from "gatsby"
import "./footer.scss"
import logo from "../../assets/img/logos/logo.png"

const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="row footer-content">
              <div className="col-xs-12 col-md-4">
                <img src={logo} alt="Codetrain" className="logo-img" />
              </div>
              <div className="col-6 col-md-4">
                <h5 className="text-uppercase">Company</h5>
                <ul className="footer-content__links">
                  {/* <li>
                    <Link to="/about">About</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/careers">Careers</Link>
                  </li> */}
                  <li>
                    <a href="https://medium.com/@codetrain">Blog</a>
                  </li>
                  <li>
                    <Link to="/#contact">Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="col-6 col-md-4">
                <h5 className="text-uppercase">Follow us</h5>
                <ul className="footer-content__links">
                  <li>
                    <a href="https://web.facebook.com/codetraingh/?_rdc=1&_rdr">
                      <span className="mdi mdi-facebook-box"></span>
                      &nbsp; Facebook
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/codetrain/?originalSubdomain=gh">
                      <span className="mdi mdi-linkedin-box"></span>
                      &nbsp; LinkedIn
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/codetraingh?lang=en">
                      <span className="mdi mdi-twitter-box"></span>
                      &nbsp; Twitter
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/codetraingh/?hl=en">
                      <span className="mdi mdi-instagram"></span>
                      &nbsp; Instagram
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xs-12 col-md-12">
                <hr />
                <p className="text-center">
                  &copy; {new Date().getFullYear()} Codetrain - All Rights
                  Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
