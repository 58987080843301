import React from "react"
import { Title, Meta } from "react-head"
import MainLayout from "../components/MainLayout"
import AboutContent from "../components/about-content/aboutContent"
// import CodetrainTeam from "../components/codetrain-tem/CodetrainTeam"

const About = () => {
  return (
    <MainLayout>
      <Title>Codetrain Africa | About - Ghana | Apps coding bootcamp</Title>
      <Meta
        name="description"
        content="Codetrain was born out of the idea that when people fulfill
        their dreams, they are able to positively impact their communities."
      />
      <AboutContent />
      {/* <CodetrainTeam /> */}
    </MainLayout>
  )
}

export default About
